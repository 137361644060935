import has from 'lodash/has';

import type { Application } from '@src/@types/app';
import { B_TEST_VALUE, Experiments } from '@src/constants/abTest';
import { FILTERS_NAMES, SORT_BY_SPEC_OFFER_POSITION_EPC } from '@src/constants/filters';
import type { ListKeys } from '@src/constants/microcredits';
import { fetchOffers } from '@src/reducers/microcredits';

import { getPageListQuery } from './getPageListQuery';

interface IListParams {
    key: ListKeys;
    defaultFiltersMode: string;
}
// query параметр который не отправляется на бэк
const TOP_MFO_QUERY_PARAM = 'topMFO';

const CRM_PAGE_QUERY_PARAM = 'landing_type';

export const getSpecialPageListData = async (ctx: Application.ReduxNextPageContext, listParams: IListParams) => {
    const {
        store: { dispatch },
    } = ctx;
    const { key, defaultFiltersMode } = listParams;

    const query = getPageListQuery({ ctx, defaultFiltersMode });
    const topMFOQueryParam = ctx.query[TOP_MFO_QUERY_PARAM];

    if (Number(topMFOQueryParam) >= 1) {
        query.limit = topMFOQueryParam;
    }

    const isBVariant = ctx.req.__AB_TESTING__?.experiments[Experiments.AUTO_SORT_BY_EPC] === B_TEST_VALUE;

    if (isBVariant) {
        query.sort = SORT_BY_SPEC_OFFER_POSITION_EPC;
    }

    const phoneNumber = ctx.cookie.get()._PN_;

    const isCRMSpecial = has(ctx.query, CRM_PAGE_QUERY_PARAM);

    const clientId = ctx.cookie.get()._ym_uid;

    const filterParams = {
        [FILTERS_NAMES.LOCATION]: ctx.params.locationRoute,
        [FILTERS_NAMES.METADATA]: true,
        ...(phoneNumber && {
            [FILTERS_NAMES.PHONE_NUMBER]: phoneNumber,
        }),
        [FILTERS_NAMES.ROUTE]: { asPath: ctx.asPath ?? '', query: ctx.query ?? {} },
        ...(!isCRMSpecial &&
            Boolean(clientId) && {
                [FILTERS_NAMES.CLIENT_ID]: clientId,
            }),
    };

    await dispatch(fetchOffers({ query, params: filterParams, key }));
};
