import cn from 'classnames';
import type { NextPage } from 'next';
import { useMemo } from 'react';

import type { Application } from '@src/@types/app';
import { PageContext } from '@src/config';
import { getSpecialPageListData } from '@src/config/getSpecialPageListData';
import type { IPageContextConfig } from '@src/config/PageContext';
import { MODES } from '@src/constants/filters';
import { ListKeys } from '@src/constants/microcredits';
import { PageHeader } from '@src/containers/list/PageHeader';
import ProductList from '@src/containers/list/ProductList';
import { useListPage } from '@src/hooks/useListPage';

import styles from './styles.module.scss';

const pageConfig: IPageContextConfig = {
    listKey: ListKeys.SPEC_OFFERS,
    source: 'search_special',
    sortPanelVisible: false,
    seoLinksVisible: true,
    extraFiltersVisible: true,
    cardTermIsVisible: true,
    withBanners: true,
    withInvitationBanner: true,
    withEmailBurner: true,
    withSelectionBanner: true,
    withCardProductLink: true,
};

const SpecialOffersLandingPage: NextPage = () => {
    const listKey = ListKeys.SPEC_OFFERS;
    const { filter, seo, pathname } = useListPage(listKey);

    const config = useMemo(() => ({ ...pageConfig, withMonetizationCardLogoClick: true, listKey }), [listKey]);

    return (
        <PageContext.Provider value={config}>
            <PageHeader className={cn(styles.page_header)} seo={seo} filter={filter} />
            <ProductList className={styles.product_list} filter={filter} pathname={pathname} />
        </PageContext.Provider>
    );
};

SpecialOffersLandingPage.getInitialProps = async (ctx: Application.ReduxNextPageContext) => {
    await getSpecialPageListData(ctx, {
        key: ListKeys.SPEC_OFFERS,
        defaultFiltersMode: MODES.SPEC_OFFERS,
    });
};

export default SpecialOffersLandingPage;
